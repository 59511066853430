<script setup lang="ts">
  // const questions = [
  //   'Как невролог может помочь?',
  //   'Почему нужно обращаться к неврологу',
  //   'Частые болезни, с которыми обращаются к неврологу:'
  // ];

  const preparationEl = ref();
  const questionsEl = ref();

  const navStore = useNeurologistNavigationStore();

  useIntersectionObserver(
    preparationEl,
    ([{ isIntersecting }]) => {
      navStore.navigationTabsVisibility.preparation = isIntersecting;
    },
    {
      rootMargin: '-120px 0px 0px 0px'
    }
  );
  useIntersectionObserver(
    questionsEl,
    ([{ isIntersecting }]) => {
      navStore.navigationTabsVisibility.questions = isIntersecting;
    },
    {
      rootMargin: '-120px 0px 0px 0px'
    }
  );
</script>

<template>
  <div class="main-container bottom-content">
    <div class="preparation-with-questions">
      <div id="preparation" ref="preparationEl" class="preparation-container scroll-normalize">
        <span class="preparation-title">Подготовка к приёму</span>
        <span class="preparation">Рекомендуем прибыть за 10-15 минут до назначенного времени, чтобы спокойно оформить документы. Пожалуйста, подготовьте информацию о вашей медицинской истории, включая заболевания, операции, лекарства и аллергии. Если у вас есть результаты анализов или снимки, которые вы проводили ранее, просим также предоставить их для более точной оценки. Ваше внимание к деталям поможет нам обеспечить наилучшее обслуживание.</span>
      </div>
      <!-- <div id="questions" ref="questionsEl" class="questions scroll-normalize">
        <span class="questions-title">Вопросы и ответы</span>
        <details v-for="question in questions" :key="question" class="question-container">
          <summary class="question">
            <span class="question-text">{{ question }}</span>
            <IconChevronDown class="chevron" />
          </summary>
          <div class="answer">
            <p>В компетенции неврологов входит широкий спектр патологий. Стоит обратиться за консультацией при следующих состояниях:</p>
          </div>
        </details>
      </div> -->
    </div>
    <NeurologistLayoutNotFound />
  </div>
</template>

<style scoped lang="scss">
.scroll-normalize {
  scroll-margin-top: 170px;
  @media (max-width: 1023px) {
    scroll-margin-top: 160px;
  }
}
.bottom-content {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 60px;
  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 40px;
    padding-top: 0px;
    margin-top: 24px;
  }
}
.preparation-with-questions {
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (min-width: 1023px) {
    max-width: 673px;
  }
}
.preparation-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.preparation-title {
  color: #262633;
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  @media (max-width: 1023px) {
    font-size: 20px;
    line-height: 26px;
  }
}
.preparation {
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 24px;
  }
}
.questions {
  display: flex;
  flex-direction: column;
}
.questions-title {
  color: #262633;
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 24px;
  @media (max-width: 1023px) {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }
}
summary {
  list-style: none;
  &::-webkit-details-marker {
    display: none;
  }
}
.question-container {
  padding: 24px 0px;
  width: 100%;
  border-top: 1px solid #E4E7EF;
  border-bottom: 1px solid #E4E7EF;
}
.question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.question-text {
  color: #262633;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.chevron {
  font-size: 28px;
}
details[open] .chevron {
  transform: rotate(180deg);
}
.answer {
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
p {
  margin-top: 19px;
}
</style>
