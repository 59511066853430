<script setup lang="ts">
  interface Props {
    carouselItems: {
      image: number
      titleText: string
      yearsText: string
      contentText: string
    }[]
  }

  const props = defineProps<Props>();

  const carouselElement = ref(null);

  const progressBar = computed(() => {
    // 70.73% это ширина всего progressBar (см. ниже), вычитаем из-за особенности css relative right
    return counter.value < 100 ? `${70.73 - counter.value}%` : '0%';
  });

  const hideContainer = useMediaQuery('(max-width: 715px)');
  const { sourceType } = useMouse();
  const { y } = useWindowScroll({ behavior: 'smooth' });

  // 12px ширина круга с которого начинается отсчёт до 41px (100%), он занимает 29.27%
  // 100% - 29.27% = 70,73% столько нужно заполнить равными отрезками
  // 8000ms (весь интервал) / 70,73% ~= 113ms
  // 113ms = 1% progressBar
  const { counter, reset } = useInterval(113, { controls: true });

  const { state, index, next, prev, go } = useCycleList(props.carouselItems);

  const { direction } = usePointerSwipe(carouselElement, {
    onSwipeEnd() {
      if (direction.value === 'left') {
        next();
        return;
      }
      if (direction.value === 'right') {
        prev();
        return;
      }
      if (sourceType.value !== 'touch') {
        return;
      }
      if (direction.value === 'up') {
        y.value += 250;
        return;
      }
      if (direction.value === 'down') {
        y.value -= 250;
      }
    }
  });

  const getNext = (n: number) => {
    const length = props.carouselItems.length;
    const next = index.value + n;
    if (next >= length) {
      return props.carouselItems[next - length];
    }
    return props.carouselItems[next];
  };

  onMounted(() => {
    setInterval(() => {
      next();
      reset();
    }, 8000);
  });
</script>

<template>
  <div :class="hideContainer ? '':'main-container'">
    <div ref="carouselElement" class="carousel-container">
      <TransitionGroup name="slide">
        <MainPageCarouselItem v-bind="state" :key="state.image" />
        <MainPageCarouselItem v-bind="getNext(1)" :key="getNext(1).image" />
        <MainPageCarouselItem v-bind="getNext(2)" :key="getNext(2).image" />
      </TransitionGroup>
    </div>
    <div class="carousel-control-container">
      <div class="carousel-control">
        <div :class="`control ${index === 0 ? 'active':''}`" @click="go(0)"></div>
        <div :class="`control ${index === 1 ? 'active':''}`" @click="go(1)"></div>
        <div :class="`control ${index === 2 ? 'active':''}`" @click="go(2)"></div>
        <div :class="`control ${index === 3 ? 'active':''}`" @click="go(3)"></div>
        <div :class="`control ${index === 4 ? 'active':''}`" @click="go(4)"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding-bottom: 40px;
  overflow: hidden;
  cursor: grab;
}

.slide-move {
  transition: all 0.15s ease-out;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.25s ease-out;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.carousel-control-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 16px 10px;
}
.carousel-control {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
}
.control {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #E4E7EF;
  cursor: pointer;
}
.active {
  width: 41px;
  border-radius: 20px;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: v-bind(progressBar);
    border-radius: 20px;
    background: #2D7FF9;
  }
}
</style>
