<script setup lang="ts">
  const carouselItems = [
    {
      image: 1,
      titleText: 'Лучшая клиника',
      yearsText: '2023 • 2022 • 2021',
      contentText: 'ПроДокторов'
    },
    {
      image: 2,
      titleText: 'Выбор пациентов',
      yearsText: '2022 • 2021 • 2020',
      contentText: 'СберЗдоровье'
    },
    {
      image: 5,
      titleText: 'Лучший медцентр',
      yearsText: '2023',
      contentText: '2ГИС'
    },
    {
      image: 3,
      titleText: 'Выбор пациентов',
      yearsText: '2022 • 2021 • 2020',
      contentText: 'НаПоправку'
    },
    {
      image: 4,
      titleText: 'Хорошее место',
      yearsText: '2023 • 2022 • 2021',
      contentText: 'Яндекс'
    }
  ];

  const isDesktop = useMediaQuery('(min-width: 1023px)');

  const infoEl = ref();
  const pricesEl = ref();

  const navStore = useNeurologistNavigationStore();

  useIntersectionObserver(
    infoEl,
    ([{ isIntersecting }]) => {
      navStore.navigationTabsVisibility.info = isIntersecting;
    },
    {
      rootMargin: '-120px 0px 0px 0px'
    }
  );
  useIntersectionObserver(
    pricesEl,
    ([{ isIntersecting }]) => {
      navStore.navigationTabsVisibility.prices = isIntersecting;
    },
    {
      rootMargin: '-120px 0px 0px 0px'
    }
  );
</script>

<template>
  <div class="neurologist-top">
    <NeurologistLayoutInfo id="info" ref="infoEl" class="scroll-normalize" />
    <ClientOnly>
      <div v-if="isDesktop" class="main-container desktop-carousel carousel-margins">
        <MainPageCarouselItem
          v-for="item in carouselItems"
          :key="item.contentText"
          v-bind="item"
        />
      </div>
      <MainPageCarousel v-else :carousel-items="carouselItems" class="carousel-margins" />
    </ClientOnly>
    <NeurologistLayoutPrices id="prices" ref="pricesEl" class="scroll-normalize" />
  </div>
</template>

<style scoped lang="scss">
.scroll-normalize {
  scroll-margin-top: 170px;
  @media (max-width: 1023px) {
    scroll-margin-top: 160px;
  }
}
.neurologist-top {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  padding-bottom: 12px;
}
.desktop-carousel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-bottom: 40px;
  overflow: hidden;
}
.carousel-margins {
  margin-top: 56px;
  margin-bottom: 56px;
}
</style>
