<template>
  <div class="main-container info-container">
    <div class="info-text-container">
      <div class="info-text">
        Наша клиника предлагает высокопрофессиональное лечение неврологических заболеваний под руководством опытных специалистов. Наши неврологи - лучшие в своей области врачи-эксперты, которые владеют передовыми методиками диагностики и лечения. <br>Индивидуальный подход к каждому пациенту, высокий уровень внимания к деталям и результатам, а также использование последних медицинских достижений - наши главные приоритеты. Можете быть уверены, что ваше здоровье находится в надежных руках опытных и заботливых специалистов.
      </div>
      <details class="question-container">
        <summary class="question">
          <span class="question-text">Что лечит невролог</span>
          <IconChevronDown class="chevron" />
        </summary>
        <div class="answer">
          <p>В компетенции неврологов входит широкий спектр патологий. Стоит обратиться за консультацией при следующих состояниях:</p>
          <ul>
            <li><span><NuxtLink to="/zabolevaniya/mezhpozvonochnaya-gryzha">Межпозвоночные грыжи</NuxtLink>;</span></li>
            <li><span><NuxtLink to="/zabolevaniya/bolezni-nervnoy-sistemy/radikulit-lechenie">Радикулит</NuxtLink>;</span></li>
            <li><span>Нарушения чувствительности (тактильной, температурной, болевой);</span></li>
            <li><span>Нарушения походки, координации движений;</span></li>
            <li><span><NuxtLink to="/zabolevaniya/golovnye-boli">Головные боли</NuxtLink> (в том числе мигрени);</span></li>
            <li><span>Острые и хронические <NuxtLink to="/zabolevaniya/bolezni-kostno-myshechnoy-sistemy/bol-v-spine-lechenie">боли в спине</NuxtLink>;</span></li>
            <li><span><NuxtLink to="/zabolevaniya/myshechnaya-slabost">Мышечная слабость</NuxtLink>;</span></li>
            <li><span><NuxtLink to="/zabolevaniya/simptomy-priznaki-i-otkloneniya-ot-normy/golovokruzhenie-lechenie">Головокружения</NuxtLink>;</span></li>
            <li><span>Ухудшения памяти;</span></li>
            <li><span><NuxtLink to="/zabolevaniya/bolezni-sistemy-krovoobrascheniya/posledstviya-insulta-lechenie">Последствия инсультов</NuxtLink> и травм головного и спинного мозга.</span></li>
          </ul>
        </div>
      </details>
      <details class="question-container">
        <summary class="question">
          <span class="question-text">Как работает невролог</span>
          <IconChevronDown class="chevron" />
        </summary>
        <div class="answer">
          <p>На приеме врач проводит сбор жалоб и осмотр. Важной частью диагностики является неврологический осмотр, в ходе которого всесторонне оценивается функциональное состояние нервной системы.</p>
          <img src="~/assets/img/neurologist-layout/answer.png" alt="answer-neurologist" class="answer-img">
          <div class="answer-img-text">
            Врач исследует сухожильные рефлексы при помощи легкого удара молоточка по сухожилию мышцы
          </div>
          <p style="margin: 16px 0px">
            Данный этап зачастую играет главную роль в постановке диагноза, позволяя врачу сформировать представление о заболевании.
          </p>
          <div class="answer-block">
            <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
            Подтвердить диагноз и контролировать динамику лечения неврологу помогают инструментальные методы исследования: <NuxtLink to="/uslugi/elektroenczefalografiya-eeg" style="text-decoration: underline;">электроэнцефалография</NuxtLink>, электромиография, УЗИ, МРТ, КТ.
          </div>
        </div>
      </details>
    </div>
    <div class="info-doctor-container">
      <span class="info-doctor-title">Информация проверена экспертом <IconShieldCheck filled class="icon-shield" /></span>
      <div class="info-doctor">
        <div class="info-doctor-img"></div>
        <div class="info">
          <NuxtLink to="/vrachi/klimenko-inna-stanislavovna" class="info-doctor-name">
            Клименко Инна Станиславовна
          </NuxtLink>
          <span class="info-doctor-specialization">Невролог / Стаж 16 лет / Кандидат медицинских наук</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.info-container {
  display: flex;
  gap: 60px;
  margin-top: 40px;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
}
.info-text-container {
  display: flex;
  flex-direction: column;
  max-width: 673px;
}
.info-text {
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 24px;
}
summary {
  list-style: none;
  &::-webkit-details-marker {
    display: none;
  }
}
.question-container {
  padding: 24px 0px;
  width: 100%;
  border-top: 1px solid #E4E7EF;
  border-bottom: 1px solid #E4E7EF;
}
.question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.question-text {
  color: #262633;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.chevron {
  font-size: 28px;
}
details[open] .chevron {
  transform: rotate(180deg);
}
.answer {
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
p {
  margin-top: 19px;
}
ul {
  list-style-type: circle;
  list-style-position: inside;
  margin-top: 16px;
  & li span {
    margin-left: -10px;
  }
  & li a {
    text-decoration: underline;
  }
}
.answer-img {
  max-width: 100%;
  height: auto;
  margin-top: 16px;
  margin-bottom: 8px;
}
.answer-img-text {
  color: #878FA2;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.014px;
}
.answer-block {
  padding: 16px;
  border-radius: 15px;
  background-color: #F5F6F7;
  color: #171E36;
  font-feature-settings: 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.09px;
}
.info-doctor-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.info-doctor-title {
  color: #262633;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.icon-shield {
  font-size: 20px;
  margin: 0;
}
.info-doctor-img {
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background: url(~/assets/img/neurologist-layout/doctor.png) lightgray -17.911px -4.788px / 170.525% 170.525% no-repeat;
}
.info-doctor {
  display: flex;
  align-items: center;
  gap: 12px;
}
.info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.info-doctor-name {
  color: #2D7FF9 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  text-decoration: none;
}
.info-doctor-specialization {
  color: #666E81;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
</style>
