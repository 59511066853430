<script setup lang="ts">
  const { showHelpModal } = storeToRefs(useBookingStore());
</script>

<template>
  <div class="main-container prices-container">
    <div class="prices-title">
      Цены
    </div>
    <div class="prices">
      <div class="price-container">
        <div class="name-with-price">
          <span class="name">Консультация невролога</span>
          <span class="price">От 1500 ₽</span>
        </div>
        <button type="button" class="price-button" @click="showHelpModal = true">
          Записаться
        </button>
      </div>
      <!-- <div class="price-line"></div>
      <div class="price-container">
        <div class="name-with-price">
          <span class="name">Консультация невролога 45 мин</span>
          <span class="price">От 1500 ₽</span>
        </div>
        <button type="button" class="price-button">
          Записаться
        </button>
      </div> -->
    </div>
    <span class="prices-bottom-text">* Мы принимаем все меры по своевременному обновлению размещенного на сайте прайс-листа, однако во избежание возможных недоразумений, советуем уточнять стоимость услуг по телефону +7 (495) 292-34-77. Размещенный прайс не является офертой.</span>
  </div>
</template>

<style scoped lang="scss">
.prices-container {
  display: flex;
  flex-direction: column;
}
.prices-title {
  color: #262633;
  font-family: 'Roboto';
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  @media (max-width: 1023px) {
    font-size: 20px;
    line-height: 130%;
  }
}
.prices {
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  border: 1px solid #E4E7EF;
  margin-top: 32px;
  margin-bottom: 16px;
  @media (max-width: 1023px) {
    margin-top: 16px;
  }
}
.price-line {
  border-bottom: 1px solid #E4E7EF;
}
.price-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  @media (max-width: 1023px) {
    align-items: end;
  }
}
.name-with-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: start;
    flex-grow: 0;
    gap: 8px;
  }
}
.name {
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 24px;
  }
}
.price {
  color: #262633;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 24px;
  }
}
.price-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 15px;
  background-color: #2D7FF9;
  padding: 14px 32px;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  margin-left: 160px;
  @media (max-width: 1023px) {
    margin: 0;
    height: 32px;
    border-radius: 21px;
    padding: 14px 16px;
    font-size: 14px;
    line-height: 18px;
  }
}
.prices-bottom-text {
  color: #878FA2;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
}
</style>
