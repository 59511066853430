export const useNeurologistNavigationStore = defineStore('neurologistNavigationStore', () => {
  const navigationTabsVisibility = ref({
    info: false,
    prices: false,
    doctors: false,
    preparation: false,
    questions: false
  });

  const currentActiveTab = computed(() => {
    const tabs = ['info', 'prices', 'doctors', 'preparation', 'questions'] as Array<keyof typeof navigationTabsVisibility.value>; // js don't guarantee object property order, so Object.entries(tabs) don't work
    const result = tabs.find((tab) => navigationTabsVisibility.value[tab] === true);
    if (result !== undefined) {
      return result;
    }
    // return 'questions';
    return 'preparation';
  });

  return { navigationTabsVisibility, currentActiveTab };
});
