<script setup lang="ts">
  import Breadcrumbs from '~/UI/Breadcrumbs.vue';
  import { PageInfoService } from '~/EastclinicVueApi';

  const { showModal } = storeToRefs(useSearchPanelStore());
  const { pageInfo } = storeToRefs(usePageInfoStore());

  const { data: catalogData } = await useLazyAsyncData(
    `catalogData-${pageInfo.value?.id}`,
    () => PageInfoService.getCatalogData(pageInfo.value)
  );
  const searchEl = ref();
  const navigationEl = ref();

  const doctorsStart = ref();
  const doctorsEnd = ref();

  const { top: searchPosition } = useElementBounding(searchEl);
  const { top: navigationPosition } = useElementBounding(navigationEl);

  const isStickySearchPanel = useMediaQuery('(min-width: 1023px)');

  const isSearchPanelOnHeader = computed(() => {
    return searchPosition.value < 24; // 23 is padding-top value in px
  });
  const isSearchPanelOnDefaultPosition = computed(() => {
    return showModal.value === false && searchPosition.value >= 88;
  });
  const isNavigationOnHeader = computed(() => {
    return navigationPosition.value <= 100; // 100 is search height with padding
  });

  const navStore = useNeurologistNavigationStore();

  const { top: start } = useElementBounding(doctorsStart);
  const { top: end } = useElementBounding(doctorsEnd);

  watch(start, (startNewValue) => {
    // 120 px is header
    // 0 is too low for doctors, so 80 px for include paddings
    if (startNewValue - 120 < 80 && end.value - 120 > 0) {
      navStore.navigationTabsVisibility.doctors = true;
    } else {
      navStore.navigationTabsVisibility.doctors = false;
    }
  });
</script>

<template>
  <div>
    <Header />
    <div
      :class="`layout-search-panel white-container
        ${isSearchPanelOnDefaultPosition ? 'before-header':'after-header'}
        ${isStickySearchPanel ? 'sticky-search':''}
        ${isSearchPanelOnHeader ? 'header-top': ''}
        ${isNavigationOnHeader ? 'header-bottom': ''}
      `"
    >
      <SearchPanel ref="searchEl" />
    </div>
    <div class="white-container">
      <div class="main-container breadcrumbs-container">
        <Breadcrumbs :items="pageInfo?.breadcrumbs ?? []" />
        <div>
          <h1 class="main-h1">
            {{ pageInfo?.pagetitle }}
          </h1>
        </div>
      </div>
      <div class="main-container">
        <div class="breadcrumbs-neurologist-text">
          Невролог — это специалист, занимающийся профилактикой, диагностикой и лечением заболеваний центральной и периферической нервной системы.
        </div>
        <NuxtLink to="#doctors" class="breadcrumbs-neurologist-button">
          Выбрать врача
        </NuxtLink>
      </div>
    </div>
    <NeurologistLayoutNavigation ref="navigationEl" :style="{backgroundColor: isNavigationOnHeader && isStickySearchPanel ? 'transparent':'white'}" />
    <NeurologistLayoutTop class="white-container" />

    <div id="doctors" ref="doctorsStart" class="scroll-normalize" style="display: hidden"></div>
    <div>
      <slot></slot>
    </div>
    <div ref="doctorsEnd" style="display: hidden"></div>

    <div class="white-container">
      <NeurologistLayoutBottom />
      <Catalog v-if="catalogData" :title="translateCatalogData(Object.keys(catalogData.data)[0])" :data="Object.values(catalogData.data)[0]" />
      <Footer class="footer-margin" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.scroll-normalize {
  scroll-margin-top: 170px;
  @media (max-width: 1023px) {
    scroll-margin-top: 160px;
  }
}
.layout-search-panel {
  padding-top: 8px;
  padding-bottom: 24px;
  @media (max-width: 1023px) {
    padding-top: 12px;
    padding-bottom: 16px;
  }
}
.breadcrumbs-container {
  padding-bottom: 24px;
}
.before-header {
  z-index: 99;
}
.after-header {
  z-index: 101;
}
.sticky-search {
  position: sticky;
  top: 0px;
}
.header-top {
  padding-top: 23px;
}
.header-bottom {
  padding-bottom: 75px;
}
.footer-margin {
  margin-top: 156px;
}
.breadcrumbs-neurologist-text {
  margin-bottom: 24px;
  max-width: 673px;
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 150%;
    max-width: 100%;
  }
}
.breadcrumbs-neurologist-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: #2D7FF9;
  width: 279px;
  padding: 14px 16px;
  margin-bottom: 40px;
  color: white !important;
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  @media (max-width: 1023px) {
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
}
</style>
